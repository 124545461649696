import React from "react";
import { useTranslation } from "react-i18next";

const LangSelect = () => {
	const { i18n } = useTranslation();

	const handleLanguageChange = (e) => {
		const newLang = e.target.value;
		i18n.changeLanguage(newLang);
	};

	return (
		// <select value={i18n.language} onChange={handleLanguageChange}>
		<form id="lang-select">
			<input
				type="radio"
				value="ko"
				name="lang"
				id="ko"
				onChange={handleLanguageChange}
				defaultChecked
			/>
			<label htmlFor="ko">한국어</label>
			|
			<input type="radio" value="en" name="lang" id="en" onChange={handleLanguageChange} />
			<label htmlFor="en">English</label>
		</form>
		// </select>
	);
};

export default LangSelect;
