import React, { useState } from "react";

import "./Selectors.scss";

import "../components/Selector/Selector";
import Selector from "../components/Selector/Selector";

import blank from "./preview-imgs/default.png";

//Sets
import set1 from "./imgs/set_1.png";
import set2 from "./imgs/set_2.png";
import set3 from "./imgs/set_3.png";
import set4 from "./imgs/set_4.png";

//Tops
import top1 from "./imgs/top_1.png";
import top2 from "./imgs/top_2.png";
import top3 from "./imgs/top_3.png";
import top4 from "./imgs/top_4.png";
import top5 from "./imgs/top_5.png";
import top6 from "./imgs/top_6.png";
import top7 from "./imgs/top_7.png";

//Pants
import pant1 from "./imgs/pant_1.png";
import pant2 from "./imgs/pant_2.png";
import pant3 from "./imgs/pant_3.png";

//Shoes
import feet1 from "./imgs/feet_1.png";
import feet2 from "./imgs/feet_2.png";
import feet3 from "./imgs/feet_3.png";
import feet4 from "./imgs/feet_4.png";

const Clothes = (props) => {
	const SET_COUNT = 4;
	const TOP_COUNT = 8;
	const BOTTOM_COUNT = 4;
	const SHOES_COUNT = 5;
	// let [selectedOption, setSelectedOption] = useState(props.current);

	// let onClickOption = (option) => {
	// 	setSelectedOption(option);
	// 	props.select(option);
	// };

	let [selectedSet, setSelectedSet] = useState(props.currentSet);
	let [selectedTop, setSelectedTop] = useState(props.currentTop);
	let [selectedPant, setSelectedPant] = useState(props.currentBottom);
	let [selectedFeet, setSelectedFeet] = useState(props.currentFeet);

	let [setActive, setSetActive] = useState(props.setActive);

	let selectSet = (option) => {
		setSelectedSet(option);
		setSetActive(true);
		props.setSetActive(true);
		props.selectSet(option);
	};

	let selectTop = (option) => {
		setSelectedTop(option);
		setSetActive(false);
		props.setSetActive(false);
		props.selectTop(option);
	};

	let selectBottom = (option) => {
		setSelectedPant(option);
		setSetActive(false);
		props.setSetActive(false);
		props.selectBottom(option);
	};

	let selectFeet = (option) => {
		setSelectedFeet(option);
		// setSetActive(false);
		// props.setSetActive(false);
		props.selectFeet(option);
	};

	let set = [];

	let set_imgs = [set1, set2, set3, set4];
	let set_names = ["날라리 1", "날라리 2", "청청", "팔콘"];

	for (let i = 0; i < SET_COUNT; i++) {
		set.push(
			<Selector
				isSelected={selectedSet !== i ? false : true}
				key={i}
				type="set"
				image={set_imgs[i]}
				onClick={() => {
					selectSet(i);
				}}>
				{set_names[i]}
			</Selector>
		);
	}

	let top = [];
	let top_imgs = [blank, top1, top2, top3, top4, top5, top6, top7];
	let top_names = [
		"없음",
		"반소매흰티",
		"1970",
		"검정민소매티",
		"더티화이트티",
		"폴로티",
		"빨간후드티",
		"초록자켓",
	];

	for (let i = 0; i < TOP_COUNT; i++) {
		top.push(
			<Selector
				isSelected={selectedTop !== i ? false : true}
				key={i}
				type="top"
				image={top_imgs[i]}
				onClick={() => {
					selectTop(i);
				}}>
				{top_names[i]}
			</Selector>
		);
	}

	let pants = [];
	let pants_imgs = [blank, pant1, pant2, pant3];
	let pants_names = ["없음", "검정바지", "찢청", "청바지"];

	for (let i = 0; i < BOTTOM_COUNT; i++) {
		pants.push(
			<Selector
				isSelected={selectedPant !== i ? false : true}
				key={i}
				type="pant"
				image={pants_imgs[i]}
				onClick={() => {
					selectBottom(i);
				}}>
				{pants_names[i]}
			</Selector>
		);
	}

	let shoes = [];
	let shoes_imgs = [blank, feet1, feet2, feet3, feet4];
	let shoes_names = ["없음", "구두", "남색운동화", "빨간운동화", "양말"];

	for (let i = 0; i < SHOES_COUNT; i++) {
		shoes.push(
			<Selector
				isSelected={selectedFeet !== i ? false : true}
				key={i}
				type="shoes"
				image={shoes_imgs[i]}
				onClick={() => {
					selectFeet(i);
				}}>
				{shoes_names[i]}
			</Selector>
		);
	}

	return (
		<div id="clothes-select" className="selector-area">
			<div className="title-wrapper">
				<div className="title">옷을 입혀 주세요.</div>
				{/* <div className="subtitle"></div> */}
			</div>

			<div className="subtitle">상의</div>
			<div className="col-3-grid">{top}</div>

			<div className="subtitle">하의</div>
			<div className="col-3-grid">{pants}</div>

			<div className="subtitle">신발</div>
			<div className="col-3-grid">{shoes}</div>

			<div className="subtitle">세트</div>
			<div className="col-3-grid">{set}</div>
		</div>
	);
};

export default Clothes;
