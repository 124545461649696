import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import html2canvas from "html2canvas";

// import Type from "./selectors/Type";
import Face from "./selectors/Face";
import Hair from "./selectors/Hair";
import Clothes from "./selectors/Clothes";
import Accessory from "./selectors/Accessory";
// import Background from "./selectors/Background";

import Button from "./components/Button/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function Generator(props) {
	const { t } = useTranslation();

	const height = props.height;

	/* Options to Select */
	// const [type, setType] = useState(0);
	const type = 0;
	const [face, setFace] = useState(0);
	const [hair, setHair] = useState(0);
	// const [background, setBackground] = useState(0);

	/* Accessories can have multiple types */
	// const [accessory, setAccessory] = useState(0);

	const [headAcc, setHeadAcc] = useState(0);
	const [faceAcc, setFaceAcc] = useState(0);

	// clothes

	const [setActive, setSetActive] = useState(false);
	const [clothset, setClothSet] = useState(0);
	const [top, setTop] = useState(0);
	const [bottom, setBottom] = useState(0);
	const [feet, setFeet] = useState(0);

	/* Proceed and return steps */
	const FINAL_STEP = 3;
	const [currentStep, setCurrentStep] = useState(0);

	let proceedStep = () => {
		setCurrentStep(currentStep + 1);
	};

	let returnStep = () => {
		setCurrentStep(currentStep - 1);
	};

	let reload = () => {
		window.location.reload();
	};

	/* Downloadable Image generator */
	const ref = React.createRef();
	const [finished, setFinished] = useState(false);

	let finishGeneration = () => {
		setFinished(true);
		download();
	};

	let downloadable = new Image();

	let download = () => {
		const element = ref.current;

		html2canvas(element).then((canvas) => {
			downloadable.src = canvas.toDataURL("image/png");
		});
		document.querySelector("main").prepend(downloadable);
	};

	let currentElement;

	switch (currentStep) {
		case 0:
			// 	currentElement = <Type select={setType} current={type} />;
			// 	break;
			// case 1:
			currentElement = <Face select={setFace} current={face} type={type} />;
			break;
		case 1:
			currentElement = <Hair select={setHair} current={hair} type={type} />;
			break;
		case 2:
			currentElement = (
				<Accessory
					selectFace={setFaceAcc}
					selectHead={setHeadAcc}
					currentFace={faceAcc}
					currentHead={headAcc}
					type={type}
				/>
			);
			break;
		case 3:
			currentElement = (
				<Clothes
					selectSet={setClothSet}
					selectTop={setTop}
					selectBottom={setBottom}
					selectFeet={setFeet}
					currentSet={clothset}
					currentTop={top}
					currentBottom={bottom}
					currentFeet={feet}
					setActive={setActive}
					setSetActive={setSetActive}
					type={type}
				/>
			);
			break;
		default:
			currentElement = <div />;
			break;
	}

	const [lang, setLang] = useState(0);

	let message = [
		"나만의 버논을 만드는 데 성공했어요!",
		<br />,
		"#슈팅스타러버스클럽💫 해시태그를 달아 나만의 버논을 자랑해 보세요.",
		<br />,
		<br />,
		"마우스 우클릭(PC) 혹은 이미지를 길게 눌러서(모바일) 다운로드하세요.",
	];

	switch (lang) {
		case 0:
			message = [
				"나만의 버논을 만드는 데 성공했어요!",
				<br />,
				<br />,
				"#GetReadyWithVernon💫 해시태그를 달아 나만의 버논을 자랑해 보세요.",
				<br />,
				<br />,
				"마우스 우클릭(PC) 혹은 이미지를 길게 눌러서(모바일) 다운로드하세요.",
			];
			break;
		case 1:
			//Eng
			message = [
				"Congratulations!",
				<br />,
				"You now have your very own Vernon Avatar.",
				<br />,
				<br />,
				"Post the image on X/Twitter with the Hashtag #GetReadyWithVernon💫 to share with others!",
				<br />,
				<br />,
				"Right click(PC) or long-press(mobile) on the image to download.",
			];
			break;
		case 2:
			message = [
				"自分だけのバーノンを作るのに成功しました！",
				<br />,
				<br />,
				"#GetReadyWithVernon💫 ハッシュタグをつけて、 自分だけのバーノンを自慢してみてください。",
				<br />,
				<br />,
				"マウスの右クリック(PC)または画像を長押し(モバイル)してダウンロードしてください。",
			];
	}

	return !finished ? (
		<main id="generator" style={height}>
			<div id="display" ref={ref}>
				{/* <div id="background" className="display-area">
					<img
						alt="selected background"
						src={process.env.PUBLIC_URL + "/images/background/" + background + ".png"}
					/>
				</div> */}
				<div id="type" className="display-area">
					<img alt="selected face type" src={process.env.PUBLIC_URL + "/images/base.png"} />
				</div>
				<div id="face" className="display-area">
					<img
						alt="selected face"
						src={process.env.PUBLIC_URL + "/images/face/face_" + (face + 1) + ".png"}
					/>
				</div>

				<div id="hair" className="display-area">
					{hair === 0 ? (
						<img
							alt="selected hair"
							src={process.env.PUBLIC_URL + "/images_archive/acc_head/0_0.png"}
						/>
					) : (
						<img
							alt="selected hair"
							src={process.env.PUBLIC_URL + "/images/hair/hair_" + hair + ".png"}
						/>
					)}
				</div>
				<div id="clothes" className="display-area">
					{!setActive ? (
						<div>
							{/* Is not Set */}

							{bottom === 0 ? (
								<img
									alt="selected pant"
									src={process.env.PUBLIC_URL + "/images_archive/acc_head/0_0.png"}
								/>
							) : (
								<img
									alt="selected pant"
									src={process.env.PUBLIC_URL + "/images/pant/pant_" + bottom + ".png"}
								/>
							)}
							{top === 0 ? (
								<img
									alt="selected top"
									src={process.env.PUBLIC_URL + "/images_archive/acc_head/0_0.png"}
								/>
							) : (
								<img
									alt="selected top"
									src={process.env.PUBLIC_URL + "/images/top/top_" + top + ".png"}
								/>
							)}
						</div>
					) : (
						<img
							alt="selected set"
							src={process.env.PUBLIC_URL + "/images/set/set_" + (clothset + 1) + ".png"}
						/>
					)}
					{feet === 0 || (setActive && clothset === 1) ? (
						<img
							alt="selected feet"
							src={process.env.PUBLIC_URL + "/images_archive/acc_head/0_0.png"}
						/>
					) : (
						<img
							alt="selected feet"
							src={process.env.PUBLIC_URL + "/images/feet/feet_" + feet + ".png"}
						/>
					)}
				</div>
				<div id="accessory" className="display-area">
					{faceAcc === 0 ? (
						<img
							alt="selected glasses"
							src={process.env.PUBLIC_URL + "/images_archive/acc_head/0_0.png"}
						/>
					) : (
						<img
							style={{ zIndex: 5 }}
							alt="selected accessory"
							src={process.env.PUBLIC_URL + "/images/faceAcc/deco_" + faceAcc + ".png"}
						/>
					)}
					{headAcc === 0 ? (
						<img
							alt="selected headacc"
							src={process.env.PUBLIC_URL + "/images_archive/acc_head/0_0.png"}
						/>
					) : (
						<img
							style={{ zIndex: 5 }}
							alt="selected accessory"
							src={process.env.PUBLIC_URL + "/images/headAcc/deco_" + headAcc + ".png"}
						/>
					)}
				</div>
			</div>
			<div id="select-area">{currentElement}</div>
			<div id="button-area" className={currentStep === 0 ? "button-1-grid" : "button-2-grid"}>
				{currentStep === 0 ? null : (
					<Button
						type="secondary"
						onClick={() => {
							returnStep();
						}}>
						<FontAwesomeIcon icon={faChevronLeft} />
					</Button>
				)}

				{currentStep !== FINAL_STEP ? (
					<Button
						onClick={() => {
							proceedStep();
						}}>
						{t("proceed")}
					</Button>
				) : (
					<Button
						onClick={() => {
							finishGeneration();
						}}>
						{t("proceed")}
					</Button>
				)}
			</div>
		</main>
	) : (
		<main id="after-generation" style={height}>
			<div id="info-text">{message}</div>

			<div id="button-area">
				<div id="lang">
					<Button
						onClick={() => {
							setLang(0);
						}}>
						한글
					</Button>
					<Button
						onClick={() => {
							setLang(1);
						}}>
						Eng
					</Button>
					<Button
						onClick={() => {
							setLang(2);
						}}>
						日本語
					</Button>
				</div>
				{/* <TwitterShareButton
							url={"https://github.com/next-share"}
							title={"next-share is a social share buttons for your next React apps."}>
							<TwitterIcon size={32} round />
						</TwitterShareButton> */}
				{/* <Button onClick={() => {}}>📸 {t("download")}</Button> */}
				<Button
					type="secondary"
					onClick={() => {
						reload();
					}}>
					<FontAwesomeIcon icon={faRotateRight} /> {t("retry")}
				</Button>
			</div>
		</main>
	);
}

export default Generator;
