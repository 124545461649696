import React, { useState, useEffect } from "react";
import "./App.scss";

import Generator from "./Generator";
import Loading from "./Loading";

// i18n support
// import { useTranslation } from "react-i18next";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationKO from "./locales/ko/translation.json";

import LangSelect from "./LangSelect";

// import { TwitterShareButton, TwitterIcon } from "next-share";

function App() {
	/* Set window height */
	const [height, setHeight] = useState({ height: window.innerHeight });

	let resize = () => {
		setHeight({ height: window.innerHeight });
	};

	useEffect(() => {
		window.addEventListener("resize", resize);

		return () => {
			window.removeEventListener("resize", resize);
		};
	});

	const resources = {
		en: {
			translation: translationEN,
		},
		ko: {
			translation: translationKO,
		},
	};

	i18n.use(initReactI18next).init({
		resources,
		//lng: "ko", //default language
		fallbackLng: "ko",
		react: {
			wait: true,
		},
	});

	// const [started, setStarted] = useState(false);
	const [stage, setStage] = useState(0);

	const skipStage = (e) => {
		if (e.code === "Space" || e.key === "Enter") {
			let newstage = stage + 1;
			if (newstage <= 2) {
				setStage(newstage);
			}
		}
	};

	return (
		<div className="App" style={height} tabIndex={-1} onKeyDown={skipStage}>
			{stage === 0 && <Loading setStage={setStage} />}

			{/* {stage === 1 && <div />} */}
			{stage === 1 && <Generator height={height} />}
			{/* {started ? <Generator height={height} /> : <Loading setStage={setStarted} />} */}

			{stage === 2 && <LangSelect />}
		</div>
	);
}

export default App;
