// import { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";

function Loading(props) {
	// const [currentText, setCurrentText] = useState("");
	// const { t } = useTranslation();

	// useEffect(() => {
	// 	function resolve(options, callback) {
	// 		// The string to resolve
	// 		const resolveString = options.resolveString;
	// 		const combinedOptions = Object.assign({}, options, { resolveString: resolveString });

	// 		function getRandomInteger(min, max) {
	// 			return Math.floor(Math.random() * (max - min + 1)) + min;
	// 		}

	// 		function randomCharacter(characters) {
	// 			return characters[getRandomInteger(0, characters.length - 1)];
	// 		}

	// 		function doRandomiserEffect(options, callback) {
	// 			const characters = options.characters;
	// 			const timeout = options.timeout;
	// 			const partialString = options.partialString;

	// 			let iterations = options.iterations;

	// 			setTimeout(() => {
	// 				if (iterations >= 0) {
	// 					const nextOptions = Object.assign({}, options, { iterations: iterations - 1 });

	// 					// Ensures partialString without the random character as the final state.
	// 					if (iterations === 0) {
	// 						setCurrentText(partialString);
	// 					} else {
	// 						// Replaces the last character of partialString with a random character
	// 						setCurrentText(
	// 							partialString.substring(0, partialString.length - 1) + randomCharacter(characters)
	// 						);
	// 					}

	// 					doRandomiserEffect(nextOptions, callback);
	// 				} else if (typeof callback === "function") {
	// 					callback();
	// 				}
	// 			}, timeout);
	// 		}

	// 		function doResolverEffect(options, callback) {
	// 			const resolveString = options.resolveString;
	// 			const offset = options.offset;
	// 			const partialString = resolveString.substring(0, offset);
	// 			const combinedOptions = Object.assign({}, options, { partialString: partialString });

	// 			doRandomiserEffect(combinedOptions, () => {
	// 				const nextOptions = Object.assign({}, options, { offset: offset + 1 });

	// 				if (offset <= resolveString.length) {
	// 					doResolverEffect(nextOptions, callback);
	// 				} else if (typeof callback === "function") {
	// 					callback();
	// 				}
	// 			});
	// 		}

	// 		doResolverEffect(combinedOptions, callback);
	// 	}

	// 	const options = {
	// 		// Initial position
	// 		offset: 0,
	// 		// Timeout between each random character
	// 		timeout: 20,
	// 		// Number of random characters to show
	// 		iterations: 3,
	// 		// Random characters to pick from
	// 		characters: ["#", "%", "&", "-", "+", "?", "/", "\\", "=", "@", "!", "*"],
	// 		// String to resolve
	// 		resolveString: "Do you believe in \n multiverse?",
	// 	};
	// 	// resolve(options, {});
	// }, []);

	return (
		<div id="loading">
			{/* <div className="circles">
				<div id="circle-1">
					<div id="circle-2"></div>
				</div>
			</div> */}

			<div id="loading-flavor">💫</div>
			<div id="loading-flavor">2025 SVT Vernon Birthday event</div>

			<div id="loading-text">Get Ready With Vernon</div>
			{/* <div>#ShootingstarLoversclub</div>
			<div>2025 SVT Vernon Birthday event</div> */}

			{/* <div id="intro-text">{t("intro")}</div> */}

			<div id="loading-wrapper">
				<div
					id="loading-button"
					onClick={() => {
						props.setStage(1);
					}}>
					Tap to BEGIN
				</div>
			</div>
		</div>
	);
}

export default Loading;
