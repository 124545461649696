import React, { useState } from "react";

import "./Selectors.scss";

import "../components/Selector/Selector";
import Selector from "../components/Selector/Selector";

import blank from "./preview-imgs/default.png";

//Head Type
import head1 from "./imgs/deco_1.png";
import head2 from "./imgs/deco_2.png";
import head3 from "./imgs/deco_3.png";
import head4 from "./imgs/deco_5.png";
import head5 from "./imgs/deco_7.png";
import head6 from "./imgs/deco_8.png";

//Face Type
import face1 from "./imgs/deco_4.png";
import face2 from "./imgs/deco_6.png";

const Accessory = (props) => {
	const FACE_COUNT = 3;
	const HEAD_COUNT = 7;
	// let [selectedOption, setSelectedOption] = useState(props.current);

	// let onClickOption = (option) => {
	// 	setSelectedOption(option);
	// 	props.select(option);
	// };

	let [selectedFace, setSelectedFace] = useState(props.currentFace);
	let [selectedHead, setSelectedHead] = useState(props.currentHead);

	let selectFace = (option) => {
		setSelectedFace(option);
		props.selectFace(option);
	};

	let selectHead = (option) => {
		setSelectedHead(option);
		props.selectHead(option);
	};

	let face = [];

	let face_imgs = [blank, face1, face2];
	let face_names = ["없음", "안경", "왹져안경"];

	let head = [];

	let head_imgs = [blank, head1, head2, head3, head4, head5, head6];
	let head_names = ["없음", "깜고귀", "꼬깔", "아북곰귀", "왕리본", "치즈귀", "헤드셋"];

	for (let i = 0; i < FACE_COUNT; i++) {
		face.push(
			<Selector
				isSelected={selectedFace !== i ? false : true}
				key={i}
				type="accessory"
				image={face_imgs[i]}
				onClick={() => {
					selectFace(i);
				}}>
				{face_names[i]}
			</Selector>
		);
	}

	for (let i = 0; i < HEAD_COUNT; i++) {
		head.push(
			<Selector
				isSelected={selectedHead !== i ? false : true}
				key={i}
				type="accessory"
				image={head_imgs[i]}
				onClick={() => {
					selectHead(i);
				}}>
				{head_names[i]}
			</Selector>
		);
	}

	return (
		<div id="accessory-select" className="selector-area">
			<div className="title-wrapper">
				<div className="title">장식을 추가해 주세요.</div>
				{/* <div className="subtitle"></div> */}
			</div>
			<div className="subtitle">얼굴 장식</div>
			<div className="col-3-grid">{face}</div>

			<div className="subtitle">머리 장식</div>
			<div className="col-3-grid">{head}</div>
		</div>
	);
};

export default Accessory;
