import React, { useState } from "react";

import "./Selectors.scss";

import "../components/Selector/Selector";
import Selector from "../components/Selector/Selector";

import face_1 from "./imgs/face_1.png";
import face_2 from "./imgs/face_2.png";
import face_3 from "./imgs/face_3.png";
import face_4 from "./imgs/face_4.png";
import face_5 from "./imgs/face_5.png";
import face_6 from "./imgs/face_6.png";
import face_7 from "./imgs/face_7.png";
import face_8 from "./imgs/face_8.png";
import face_9 from "./imgs/face_9.png";
import face_10 from "./imgs/face_10.png";
import face_11 from "./imgs/face_11.png";

const Face = (props) => {
	const COUNT = 11;
	let [selectedOption, setSelectedOption] = useState(props.current);

	let onClickOption = (option) => {
		setSelectedOption(option);
		props.select(option);
	};

	let selectors = [];

	let selectors_imgs = [
		face_1,
		face_2,
		face_3,
		face_4,
		face_5,
		face_6,
		face_7,
		face_8,
		face_9,
		face_10,
		face_11,
	];

	let selectorNames = [
		"기본",
		"꾸러기",
		"메롱",
		"못마땅",
		"만족.",
		"웃음",
		"으..",
		"입꾹",
		"찡긋",
		"츄",
		"힝구",
	];

	for (let i = 0; i < COUNT; i++) {
		selectors.push(
			<Selector
				isSelected={selectedOption !== i ? false : true}
				key={i}
				type="face"
				image={selectors_imgs[i]}
				onClick={() => {
					onClickOption(i);
				}}>
				{selectorNames[i]}
			</Selector>
		);
	}

	return (
		<div id="face-select" className="selector-area">
			<div className="title-wrapper">
				<div className="title">얼굴 표정을 골라 주세요.</div>
				{/* <div className="subtitle"></div> */}
			</div>
			<div className="col-3-grid">{selectors}</div>
		</div>
	);
};

export default Face;
