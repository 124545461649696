import React, { useState } from "react";

import "./Selectors.scss";

import "../components/Selector/Selector";
import Selector from "../components/Selector/Selector";

import blank from "./preview-imgs/default.png";
import hair_1 from "./imgs/hair_1.png";
import hair_2 from "./imgs/hair_2.png";
import hair_3 from "./imgs/hair_3.png";
import hair_4 from "./imgs/hair_4.png";
import hair_5 from "./imgs/hair_5.png";
import hair_6 from "./imgs/hair_6.png";
import hair_7 from "./imgs/hair_7.png";
import hair_8 from "./imgs/hair_8.png";
import hair_9 from "./imgs/hair_9.png";

const Hair = (props) => {
	const COUNT = 10;
	let [selectedOption, setSelectedOption] = useState(props.current);

	let onClickOption = (option) => {
		setSelectedOption(option);
		props.select(option);
	};

	let selectors = [];

	let selectors_imgs = [
		blank,
		hair_1,
		hair_2,
		hair_3,
		hair_4,
		hair_5,
		hair_6,
		hair_7,
		hair_8,
		hair_9,
	];

	let selectorNames = [
		"없음",
		"검짧머",
		"깐머",
		"반깐갈머",
		"밤톨",
		"볶머갈발",
		"비니",
		"예뽀니비니",
		"은발",
		"캡모자",
	];

	for (let i = 0; i < COUNT; i++) {
		selectors.push(
			<Selector
				isSelected={selectedOption !== i ? false : true}
				key={i}
				type="hair"
				image={selectors_imgs[i]}
				onClick={() => {
					onClickOption(i);
				}}>
				{selectorNames[i]}
			</Selector>
		);
	}

	return (
		<div id="hair-select" className="selector-area">
			<div className="title-wrapper">
				<div className="title">머리 스타일을 골라 주세요.</div>
				{/* <div className="subtitle"></div> */}
			</div>
			<div className="col-3-grid">{selectors}</div>
		</div>
	);
};

export default Hair;
